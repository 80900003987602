import React from 'react'
import Layout from '../components/layouts/layout'
import Seo from '../components/seo/seo'
import './contact-page.sass'

const Page = () => (
  <Layout
    noEmail
    className="Contact u-wrap u-wrap--extra-left">
    <Seo
      title="Contact - Maison Hanko"
      description="Get in contact with us to find out more about our roster of directorial and illustrative talent." />
    <div className="Contact__grid">
      <div className="Contact__title-block">
        <h1 className="Contact__title">
          Get in touch
        </h1>
        <a
          className="Contact__email"
          href="mailto:hello@maisonhanko.com">
          hello@maisonhanko.com
        </a>
      </div>
      <div className="Contact__addresses">
        <section className="Contact__address">
          <h2>Commercials</h2>
          <p>
            <a href="tel:+442077497500">
              +44 (0)20 7749 7500
            </a>
          </p>
          <p>
            25 Chart Street, Shoreditch
            <br />
            London / N1 6FA
            <br />
            London, United Kingdon
          </p>
          <h3>Executive Poducer</h3>
          <p>Judy Hill</p>
        </section>
        <section className="Contact__address">
          <h2>Commercials</h2>
          <p>
            <a href="tel:+12132657470">
              +1 213 265 7470
            </a>
          </p>
          <p>
            821 Traction Avenue, Suite 101
            <br />
            Los Angeles / CA 90013
            <br />
            LA, United States
          </p>
          <h3>Executive Poducer</h3>
          <p>Juliet Tierney</p>
        </section>
        <section className="Contact__address">
          <h2>Entertainment</h2>
          <p>
            <a href="tel:+12132657470">
              +1 213 265 7470
            </a>
          </p>
          <p>
            821 Traction Avenue, Suite 101
            <br />
            Los Angeles / CA 90013
            <br />
            LA, United States
          </p>
          <h3>Executive Creative Director</h3>
          <p>Chris O'Reilly</p>
        </section>
      </div>
    </div>
  </Layout>
)

export default Page
